import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Slider from 'react-slick';
import { fromEvent } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import 'modules/slick-carousel/slick/slick.css';
import 'modules/slick-carousel/slick/slick-theme.css';

import { CustomArrow } from './custom-arrow';

import './carousel.scss';

export class Carousel extends Component {
  static propTypes = {
    iconRight: PropTypes.node,
    iconLeft: PropTypes.node,
    children: PropTypes.node,
    settings: PropTypes.shape({
      infinite: PropTypes.bool,
      arrows: PropTypes.bool,
      onNextClick: PropTypes.func,
      onPrevClick: PropTypes.func,
      speed: PropTypes.number,
      slidesToShow: PropTypes.number,
      slidesToScroll: PropTypes.number,
    }),
  };

  static defaultProps = {
    iconRight: <svg />,
    iconLeft: <svg />,
    children: null,
    settings: {
      infinite: true,
      arrows: true,
      speed: 500,
      slidesToShow: 4,
      slidesToScroll: 1,
    },
  };

  state = {
    width: null,
  };

  resizeWindowSubsription$;

  componentDidMount() {
    this.resizeWindowSubsription$ = fromEvent(window, 'resize')
      .pipe(debounceTime(200))
      .subscribe(this.setWidth);
    this.setWidth();
  }

  componentWillUnmount() {
    if (this.resizeWindowSubsription$) {
      this.resizeWindowSubsription$.unsubscribe();
    }
  }

  setWidth = () => {
    const { width } = document.querySelector('body').getBoundingClientRect();
    const { innerWidth } = window;
    const scrollBarWidth = innerWidth - width;
    let offsetX = 0;
    const leftSidebar = document.querySelector('.left-sidebar');
    const rightSidebar = document.querySelector('.right-sidebar');

    if (leftSidebar) {
      offsetX += leftSidebar.offsetWidth;
    }

    if (rightSidebar) {
      offsetX += rightSidebar.offsetWidth;
    }

    if (innerWidth >= 720) {
      offsetX += 32; // container paddings
    }

    this.setState({ width: innerWidth - offsetX - scrollBarWidth });
  }

  render() {
    const { width } = this.state;
    const {
      iconRight,
      iconLeft,
      children,
      settings,
      ...rest
    } = this.props;
    const config = {
      nextArrow: <CustomArrow icon={iconRight} />,
      prevArrow: <CustomArrow icon={iconLeft} />,
      ...rest,
    };

    return (
      <div style={{ width }}>
        <Slider {...config} {...settings}>
          {children}
        </Slider>
      </div>
    );
  }
}
