import React, { Component, createRef } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import * as R from 'ramda';
import qs from 'qs';
import { fromEvent } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { getMainLineMarkets, isEmptyOrNil } from 'core/helpers';
import {
  BET_SLIP_STATE,
  NO_RECOMMENDATION_ID,
  TOP_EVENTS_TYPES,
  STAKE_TYPES_WITH_3WAY_MAIN_MARKET,
  AI_OUTCOME_SECTION,
} from 'core/constants';

import { FormattedTag } from 'components/formatted-tag/formatted-tag';
import { RouteLink } from 'components/route-link/route-link';
import { TeamLogoPlaceholder } from 'components/team-logo-placeholder/team-logo-placeholder';
import { GameStatus } from 'components/betting-table/game-status/game-status';
import { IconArrowControl } from 'components/icons/icon-arrow-control/icon-arrow-control';
import { toTimeIfNearOrDate, removeComma, toTodayOrTomorrow } from 'helpers/date';
import { setEmptyStakes } from 'helpers/stake';
import { prepareHomeScore, prepareAwayScore } from 'helpers/score';
import { GA } from 'helpers/ga';
import {
  SPORTS_WITH_3WAY_MAIN_MARKET,
  TODAY,
  TOMORROW,
  SPORTS_WITHOUT_LIVE_SCORE,
} from '../../constants';
import { Odd } from './odd/odd';

import './market-card.scss';

const SCROLL_OFFSET = 100;

export class MarketCard extends Component {
  static propTypes = {
    locale: PropTypes.string.isRequired,
    eventId: PropTypes.number.isRequired,
    eventNumber: PropTypes.number.isRequired,
    sportId: PropTypes.number.isRequired,
    sportName: PropTypes.string.isRequired,
    leagueName: PropTypes.string.isRequired,
    leagueId: PropTypes.number.isRequired,
    teamA: PropTypes.string.isRequired,
    teamB: PropTypes.string.isRequired,
    teamALogo: PropTypes.string.isRequired,
    teamBLogo: PropTypes.string.isRequired,
    timestamp: PropTypes.number.isRequired,
    isBetAllowed: PropTypes.bool.isRequired,
    stakeTypes: PropTypes.arrayOf(PropTypes.shape()).isRequired,
    betSlipStakes: PropTypes.arrayOf(PropTypes.shape()).isRequired,
    toggleStake: PropTypes.func.isRequired,
    betSlipState: PropTypes.oneOf([
      BET_SLIP_STATE.DEFAULT,
      BET_SLIP_STATE.SUSPENDED,
      BET_SLIP_STATE.BET_FACTOR_DECREASED,
      BET_SLIP_STATE.SUCCESS,
      BET_SLIP_STATE.ERROR,
    ]).isRequired,
    isOutright: PropTypes.bool.isRequired,
    isLive: PropTypes.bool.isRequired,
    eventStatus: PropTypes.string,
    homeScore: PropTypes.number,
    awayScore: PropTypes.number,
    passedTime: PropTypes.number,
    recommendationId: PropTypes.string,
    type: PropTypes.oneOf([
      TOP_EVENTS_TYPES.ALL,
      TOP_EVENTS_TYPES.SPORT,
      TOP_EVENTS_TYPES.LEAGUE,
      TOP_EVENTS_TYPES.LIVE,
    ]).isRequired,
    setAltScore: PropTypes.string,
    sendEventClickFeedback: PropTypes.func.isRequired,
  };

  static defaultProps = {
    eventStatus: null,
    homeScore: null,
    awayScore: null,
    passedTime: null,
    recommendationId: null,
    setAltScore: null,
  };

  oddsRef = createRef();

  resizeWindowSubsription;

  state = {
    isPrevShowed: false,
    isNextShowed: false,
  };

  componentDidMount() {
    const odds = this.oddsRef && this.oddsRef.current;

    if (odds) {
      const { scrollWidth, clientWidth } = odds;
      this.resizeWindowSubsription = fromEvent(window, 'resize')
        .pipe(debounceTime(200))
        .subscribe(this.onScroll);

      if (scrollWidth > clientWidth) {
        this.setIsNextShowed(true);
      }
    }
  }

  componentDidUpdate(prevProps) {
    const { stakeTypes: prevStakeTypes } = prevProps;
    const { stakeTypes } = this.props;

    if (!R.equals(prevStakeTypes, stakeTypes)) {
      this.onScroll();
    }
  }

  componentWillUnmount() {
    if (this.resizeWindowSubsription) {
      this.resizeWindowSubsription.unsubscribe();
    }
  }

  setIsNextShowed = isNextShowed => this.setState({ isNextShowed });

  setIsPrevShowed = isPrevShowed => this.setState({ isPrevShowed });

  onClick = () => {
    const {
      type,
      sendEventClickFeedback,
      eventNumber,
      isLive,
      eventStatus,
      recommendationId,
    } = this.props;
    let label;

    if (type === TOP_EVENTS_TYPES.ALL) {
      label = 'top-events-main-to-event';
    } else if (type === TOP_EVENTS_TYPES.SPORT) {
      label = 'top-events-sports-to-event';
    } else if (type === TOP_EVENTS_TYPES.LEAGUE) {
      label = 'top-events-league-to-event';
    } else if (type === TOP_EVENTS_TYPES.LIVE) {
      label = 'top-events-inplay-to-event';
    }

    if (label) {
      GA.event({
        category: 'go-to-event-page',
        label,
      });
    }

    sendEventClickFeedback(eventNumber, isLive || eventStatus !== null, recommendationId);
  };

  onScroll = () => {
    const { isPrevShowed, isNextShowed } = this.state;

    if (this.oddsRef && this.oddsRef.current) {
      const { scrollWidth, clientWidth, scrollLeft } = this.oddsRef.current;
      const preparedWidth = Math.ceil(scrollLeft + clientWidth);

      if (isPrevShowed) {
        if (scrollLeft === 0) {
          this.setIsPrevShowed(false);
        }
      } else if (scrollLeft > 0) {
        this.setIsPrevShowed(true);
      }

      if (isNextShowed) {
        if (preparedWidth >= scrollWidth) {
          this.setIsNextShowed(false);
        }
      } else if (preparedWidth < scrollWidth) {
        this.setIsNextShowed(true);
      }
    }
  };

  onPrevClick = () => {
    const odds = this.oddsRef && this.oddsRef.current;

    if (odds) {
      const { scrollLeft } = odds;
      odds.scrollTo({
        left: scrollLeft - SCROLL_OFFSET,
        behavior: 'smooth',
      });
    }
  };

  onNextClick = () => {
    const odds = this.oddsRef && this.oddsRef.current;

    if (odds) {
      const { scrollLeft } = odds;
      odds.scrollTo({
        left: scrollLeft + SCROLL_OFFSET,
        behavior: 'smooth',
      });
    }
  };

  onTouch = (e) => {
    e.stopPropagation();
  };

  render() {
    const {
      locale,
      eventId,
      eventNumber,
      sportId,
      sportName,
      leagueName,
      teamA,
      teamB,
      teamALogo,
      teamBLogo,
      timestamp,
      isBetAllowed,
      stakeTypes,
      betSlipStakes,
      toggleStake,
      betSlipState,
      isOutright,
      leagueId,
      isLive,
      eventStatus,
      homeScore,
      awayScore,
      passedTime,
      recommendationId,
      type,
      setAltScore,
    } = this.props;
    const { isPrevShowed, isNextShowed } = this.state;
    let is3way = SPORTS_WITH_3WAY_MAIN_MARKET.includes(sportId) && !isOutright;
    const withRecommendation = type === TOP_EVENTS_TYPES.ALL || type === TOP_EVENTS_TYPES.LIVE;
    const formattedDate = toTodayOrTomorrow(timestamp);
    const marketsList = getMainLineMarkets({
      stakeTypes, isLive, eventStatus, betSlipStakes, betSlipState
    });
    const isAnyMarketExists = !!marketsList.length
    && !!marketsList.filter(({ marketStake }) => marketStake && !!marketStake.length).length;
    const isOneMarket = !!marketsList.length
    && marketsList.filter(({ marketStake }) => marketStake && !!marketStake.length).length < 2;
    const isEventInLive = isLive || eventStatus !== null;
    const preparedRecommendationId = withRecommendation ? recommendationId || NO_RECOMMENDATION_ID : null;
    let recommendationRef = null;
    let sectionBeforeEventPage = null;

    if (preparedRecommendationId) {
      if (type === TOP_EVENTS_TYPES.ALL) {
        recommendationRef = 'all';
      } else if (type === TOP_EVENTS_TYPES.LIVE) {
        recommendationRef = 'inplay';
      }
    }

    if (type === TOP_EVENTS_TYPES.ALL) {
      sectionBeforeEventPage = AI_OUTCOME_SECTION.MAIN_TOP;
    } else if (type === TOP_EVENTS_TYPES.SPORT) {
      sectionBeforeEventPage = AI_OUTCOME_SECTION.SPORT_TOP;
    } else if (type === TOP_EVENTS_TYPES.LEAGUE) {
      sectionBeforeEventPage = AI_OUTCOME_SECTION.LEAGUE_TOP;
    } else if (type === TOP_EVENTS_TYPES.LIVE) {
      sectionBeforeEventPage = AI_OUTCOME_SECTION.INPLAY_TOP;
    }

    const oddProps = {
      isBetAllowed,
      teamA,
      teamB,
      betSlipStakes,
      toggleStake,
      eventId,
      eventNumber,
      betSlipState,
      isOutright,
      leagueId,
      isEventInLive,
      recommendationId,
      type,
    };

    return (
      <div className="position-relative">
        <RouteLink
          to={`/event/${eventId}?${qs.stringify({
            isLive: isEventInLive,
            recommendationId: preparedRecommendationId,
            recommendationRef,
            sectionBeforeEventPage,
          }, { skipNulls: true })}`}
          locale={locale}
          onClick={this.onClick}
          className="market-card d-flex flex-column rounded px-1 py-1_5 pt-sm-2"
        >
          <div className="d-flex align-items-center justify-content-center mb-1">
            <TeamLogoPlaceholder imageUrl={teamALogo} sportId={sportId} className="market-card-logo" />
            <div className="market-card-date-wrapper d-flex justify-content-center">
              {isEventInLive
                ? (
                  <div className="d-flex flex-column mt-n1 text-center">
                    {!SPORTS_WITHOUT_LIVE_SCORE.includes(sportId) && (
                      <div className="text-success font-weight-bold d-flex justify-content-center">
                        <span>{prepareHomeScore(homeScore, sportId, setAltScore)}</span>
                        {/* eslint-disable-next-line react/jsx-curly-brace-presence */}
                        <span>{' - '}</span>
                        <span>{prepareAwayScore(awayScore, sportId, setAltScore)}</span>
                      </div>
                    )}

                    <GameStatus
                      locale={locale}
                      sportId={sportId}
                      eventStatus={eventStatus}
                      passedTime={passedTime}
                      className="caption text-extra-2 text-break"
                    />
                  </div>
                )
                : (
                  <span className="market-card-date caption text-extra-2 text-center">
                    {formattedDate === TODAY && <FormattedTag id="general.today" className="d-block" />}
                    {formattedDate === TOMORROW && <FormattedTag id="general.tomorrow" className="d-block" />}
                    {removeComma(toTimeIfNearOrDate(timestamp, locale))}
                  </span>
                )}
            </div>
            <TeamLogoPlaceholder imageUrl={teamBLogo} sportId={sportId} className="market-card-logo" />
          </div>

          <div className="d-flex align-items-center justify-content-between h6">
            <div className="market-card-teams-name-wr d-flex align-items-center">
              <div className="market-card-teams-border flex-fill" />
              <div className="text-truncate ml-1">{teamA}</div>
            </div>

            <div> — </div>

            <div className="market-card-teams-name-wr d-flex align-items-center">
              <div className="text-truncate mr-1">{teamB}</div>
              <div className="market-card-teams-border flex-fill" />
            </div>
          </div>

          <div className="d-flex justify-content-center align-items-center caption text-extra-3">
            <span className="flex-shrink-0">
              {sportName}
            </span>
            <span className="mr-0_5">:</span>
            <span className="text-truncate">
              {leagueName}
            </span>
          </div>
        </RouteLink>

        <div className="market-card-odds-wrapper position-absolute">
          <div
            ref={this.oddsRef}
            onScroll={this.onScroll}
            onTouchMove={this.onTouch} // TODO: should optimize?
            className="market-card-odds-scroll-bar d-flex"
          >
            {isAnyMarketExists ? (
              marketsList.map(({
                stakeTypeId,
                stakeTypeName,
                marketStake,
                isTotal,
                isHandicap,
              }, index) => {
                is3way = STAKE_TYPES_WITH_3WAY_MAIN_MARKET.includes(stakeTypeId);
                const isLastMarket = marketsList.length === index + 1;
                const marketStakeWithEmptyValue = setEmptyStakes(marketStake, is3way);

                return !R.isNil(marketStakeWithEmptyValue) && (
                  <div key={stakeTypeId} className={classNames('market-card-odds-item d-flex flex-column flex-shrink-0 position-relative', { 'is-one-market': isOneMarket, 'with-draw': is3way, 'mr-1_5': !isLastMarket })}>
                    <div className="market-name overflow-hidden caption position-absolute mt-1_5">
                      <span className="text-nowrap text-extra-3">
                        {stakeTypeName}
                      </span>
                    </div>
                    <div className="market-card-odds-group mt-4 flex-shrink-0">
                      {marketStakeWithEmptyValue.map(stake =>
                        !isEmptyOrNil(stake) ? (
                          <Odd
                            key={stake.stakeId}
                            stake={stake}
                            isHandicap={isHandicap}
                            isTotal={isTotal}
                            {...oddProps}
                          />
                        ) : (
                          <div className="odd is-not-allowed d-flex justify-content-center align-items-center text-extra-3">
                            &#8212;
                          </div>
                        ))}
                    </div>
                  </div>
                );
              })
            ) : (
              <div className={classNames('market-card-odds-item w-100', { 'with-draw': is3way })}>
                <div className="market-card-odds-group">
                  <div className="odd is-not-allowed d-flex justify-content-center align-items-center text-extra-3">
                    &#8212;
                  </div>
                  <div className="odd is-not-allowed d-flex justify-content-center align-items-center text-extra-3">
                    &#8212;
                  </div>
                  {is3way && (
                    <div className="odd is-not-allowed d-flex justify-content-center align-items-center text-extra-3">
                      &#8212;
                    </div>
                  )}
                </div>
              </div>
            )}
          </div>

          <div className={classNames('market-card-odds-prev position-absolute d-flex align-items-center pl-0_5', { invisible: !isPrevShowed })}>
            <div
              role="button"
              tabIndex="0"
              onClick={this.onPrevClick}
              onKeyPress={this.onPrevClick}
              className="market-card-odds-next-btn d-flex justify-content-center align-items-center pt-2_5"
            >
              <IconArrowControl className="d-none d-sm-block" />
            </div>
          </div>

          <div className={classNames('market-card-odds-next position-absolute d-flex align-items-center justify-content-end pr-0_5', { invisible: !isNextShowed })}>
            <div
              role="button"
              tabIndex="0"
              onClick={this.onNextClick}
              onKeyPress={this.onNextClick}
              className="market-card-odds-next-btn d-flex justify-content-center align-items-center pt-2_5"
            >
              <IconArrowControl className="d-none d-sm-block" />
            </div>
          </div>
        </div>
      </div>
    );
  }
}
