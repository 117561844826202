import React from 'react';
import PropTypes from 'prop-types';

// Avoid error: Unknown props `currentSlide`, `slideCount` props
// https://github.com/akiran/react-slick/issues/728

export const CustomArrow = ({
  currentSlide, slideCount, icon, ...remainingProps
}) => (
  <span role="button" {...remainingProps}>
    {icon}
  </span>
);

CustomArrow.propTypes = {
  currentSlide: PropTypes.number,
  slideCount: PropTypes.number,
  onClick: PropTypes.func,
  icon: PropTypes.node.isRequired,
};

CustomArrow.defaultProps = {
  currentSlide: null,
  slideCount: null,
  onClick: () => {},
};
