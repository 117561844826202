import React from 'react';
import PropTypes from 'prop-types';

export const IconRight = ({ className, onClick }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="34" height="34" viewBox="0 0 34 34" className={className} onClick={onClick}>
    <g fill="none" fillRule="evenodd" stroke="#FFF" strokeLinecap="square" transform="translate(1 1)">
      <rect width="33" height="33" x="-.5" y="-.5" strokeOpacity=".2" rx="16.5" />
      <path strokeWidth="2" d="M14.517 21.286l4.966-4.966L14.517 11" />
    </g>
  </svg>
);

IconRight.propTypes = {
  className: PropTypes.string,
  onClick: PropTypes.func,
};

IconRight.defaultProps = {
  className: null,
  onClick: () => {},
};
