import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import './top-events-preloader.scss';

const TopEventsRowPreloader = () => (
  <span className="top-events-preloader-row preloader w-100 rounded bg-main-2" />
);

export const TopEventsPreloader = ({ className }) => (
  <div className={classNames('mb-2_5', className)}>
    <div className="top-events-preloader-header preloader mb-1 ml-2 ml-sm-0 bg-main-2" />
    <div className="top-events-preloader ml-1 mr-sm-0">
      {[1, 2, 3, 4, 5].map(row => <TopEventsRowPreloader key={row} />)}
    </div>
  </div>
);

TopEventsPreloader.propTypes = {
  className: PropTypes.string,
};

TopEventsPreloader.defaultProps = {
  className: null,
};
